import { ElementRef, EventEmitter } from '@angular/core';
var SearchableSelectComponent = /** @class */ (function () {
    function SearchableSelectComponent() {
        this.multipleSelect = false;
        this.clearButton = false;
        this.selectionChange = new EventEmitter();
        this.selectionSubmit = new EventEmitter();
        this.items = [];
        this.selectedItems = [];
        this.searchableItems = [];
        this.lastSearchString = '';
        this.filteredItems = [];
        this.selectAllChecked = false;
        this.selectAllDisabled = false;
    }
    Object.defineProperty(SearchableSelectComponent.prototype, "selectItems", {
        set: function (value) {
            if (value === undefined) {
                return;
            }
            this.items = value;
            this.selectedItems = [];
            this.searchableItems = value.map(function (x) { return ({ key: x.key, value: x.value.toUpperCase() }); });
            this.filterSelectItems(this.lastSearchString);
        },
        enumerable: true,
        configurable: true
    });
    SearchableSelectComponent.prototype.onSearchInputChange = function (event) {
        event.stopPropagation();
        this.filterSelectItems(event.target.value);
        this.setSelectAllChecked();
    };
    SearchableSelectComponent.prototype.onSelectionChange = function (event) {
        this.formValue = event.value;
        this.selectedItems = this.multipleSelect ? event.value.slice() : [event.value];
        this.setSelectAllChecked();
        this.selectionChange.emit(this.selectedItems);
    };
    SearchableSelectComponent.prototype.onOpenedChange = function (opened) {
        if (opened) {
            this.searchInput.nativeElement.focus();
        }
        else {
            this.selectionSubmit.emit(this.selectedItems);
        }
    };
    SearchableSelectComponent.prototype.onSelectAllChange = function (event) {
        this.selectAllChecked = event.checked;
        for (var _i = 0, _a = this.filteredItems.map(function (x) { return x.key; }); _i < _a.length; _i++) {
            var key = _a[_i];
            this.setSelectedValue(key, event.checked);
        }
    };
    SearchableSelectComponent.prototype.onClearClick = function (event) {
        event.stopPropagation();
        this.formValue = null;
        this.selectedItems = [];
        this.searchInput.nativeElement.value = '';
        this.selectAllChecked = false;
        this.filterSelectItems('');
        this.selectionChange.emit(this.selectedItems);
        this.selectionSubmit.emit(this.selectedItems);
    };
    SearchableSelectComponent.prototype.filterSelectItems = function (searchString) {
        var trimmedSearchString = searchString ? searchString.trim().toUpperCase() : '';
        this.lastSearchString = trimmedSearchString;
        if (trimmedSearchString.length === 0) {
            this.filteredItems = this.items.slice();
        }
        else {
            var filteredKeys_1 = this.searchableItems.filter(function (x) { return x.value.includes(trimmedSearchString); }).map(function (x) { return x.key; });
            this.filteredItems = this.items.filter(function (x) { return filteredKeys_1.includes(x.key); }).slice();
            this.selectedItems = this.selectedItems.filter(function (x) { return filteredKeys_1.includes(x); }).slice();
            this.selectionChange.emit(this.selectedItems);
        }
        this.selectAllDisabled = this.filteredItems.length === 0;
    };
    SearchableSelectComponent.prototype.setSelectedValue = function (key, value) {
        if (value && !this.selectedItems.includes(key)) {
            this.selectedItems.push(key);
        }
        else if (!value && this.selectedItems.includes(key)) {
            this.selectedItems.splice(this.selectedItems.indexOf(key), 1);
        }
        this.formValue = this.selectedItems.slice();
        this.selectedItems = this.selectedItems.slice();
        this.selectionChange.emit(this.selectedItems);
    };
    SearchableSelectComponent.prototype.setSelectAllChecked = function () {
        var _this = this;
        if (!this.multipleSelect) {
            return;
        }
        this.selectAllChecked = this.filteredItems.length > 0 && this.filteredItems.every(function (x) { return _this.selectedItems.includes(x.key); });
    };
    SearchableSelectComponent.prototype.getHoverText = function () {
        if (!this.multipleSelect || this.selectedItems.length === 0) {
            return '';
        }
        return this.selectedItems.join(', ');
    };
    return SearchableSelectComponent;
}());
export { SearchableSelectComponent };
