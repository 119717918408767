import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material';
import { KeyValuePair } from '@app/core/models/keyValuePair';

@Component({
  selector: 'vt-searchable-select',
  templateUrl: 'searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss'],
})
export class SearchableSelectComponent {
  @Input() multipleSelect = false;
  @Input() clearButton = false;
  @Input() placeholder: string;

  @Input() 
  set selectItems(value: KeyValuePair[]) {
    if (value === undefined) {
      return;
    }

    this.items = value;
    this.selectedItems = [];
    this.searchableItems = value.map(x => ({ key: x.key, value: x.value.toUpperCase() }));

    this.filterSelectItems(this.lastSearchString);
  }

  @Output() selectionChange = new EventEmitter<string[]>();
  @Output() selectionSubmit = new EventEmitter<string[]>();

  @ViewChild('searchInput') searchInput: ElementRef;

  items: KeyValuePair[] = [];
  formValue: string | string[];
  selectedItems: string[] = [];
  searchableItems: KeyValuePair[] = [];

  lastSearchString = '';
  filteredItems: { key: string, value: string }[] = [];

  selectAllChecked = false;
  selectAllDisabled = false;

  onSearchInputChange(event: any) {
    event.stopPropagation();

    this.filterSelectItems(event.target.value);
    this.setSelectAllChecked();
  }

  onSelectionChange(event: MatSelectChange) {
    this.formValue = event.value;
    this.selectedItems = this.multipleSelect ? [...event.value] : [event.value];

    this.setSelectAllChecked();

    this.selectionChange.emit(this.selectedItems);
  }

  onOpenedChange (opened: boolean) {
    if (opened) {
      this.searchInput.nativeElement.focus();
    }
    else {
      this.selectionSubmit.emit(this.selectedItems);
    }
  }

  onSelectAllChange(event: any) {
    this.selectAllChecked = event.checked;

    for (const key of this.filteredItems.map(x => x.key)) {
      this.setSelectedValue(key, event.checked);
    }
  }

  onClearClick(event: any) {
    event.stopPropagation();

    this.formValue = null;
    this.selectedItems = [];

    this.searchInput.nativeElement.value = '';
    this.selectAllChecked = false;
    this.filterSelectItems('');

    this.selectionChange.emit(this.selectedItems);
    this.selectionSubmit.emit(this.selectedItems);
  }

  filterSelectItems(searchString: string) {
    const trimmedSearchString = searchString ? searchString.trim().toUpperCase() : '';
    this.lastSearchString = trimmedSearchString;

    if (trimmedSearchString.length === 0) {
      this.filteredItems = [...this.items]; 
    }
    else {
      const filteredKeys = this.searchableItems.filter(x => x.value.includes(trimmedSearchString)).map(x => x.key);
      this.filteredItems = [...this.items.filter(x => filteredKeys.includes(x.key))];

      this.selectedItems = [...this.selectedItems.filter(x => filteredKeys.includes(x))];
      this.selectionChange.emit(this.selectedItems);
    }

    this.selectAllDisabled = this.filteredItems.length === 0;
  }

  setSelectedValue(key: string, value: boolean) {
    if (value && !this.selectedItems.includes(key)) {
      this.selectedItems.push(key);
    }
    else if (!value && this.selectedItems.includes(key)) {
      this.selectedItems.splice(this.selectedItems.indexOf(key), 1);
    }
    
    this.formValue = [...this.selectedItems];
    this.selectedItems = [...this.selectedItems];
    this.selectionChange.emit(this.selectedItems);
  }

  setSelectAllChecked() {
    if (!this.multipleSelect) {
      return;
    }

    this.selectAllChecked = this.filteredItems.length > 0 && this.filteredItems.every(x => this.selectedItems.includes(x.key));
  }

  getHoverText() {
    if (!this.multipleSelect || this.selectedItems.length === 0) {
      return '';
    }

    return this.selectedItems.join(', ');
  }
}
